 <template>
	<!-- filters component -->
	<div class="filters-container">
		<!-- horizontal filters -->
		<div class="filter-container--horizontal"
			:class="{ 'has-error' : error }"
			>
			<div class="filter-options filter-options--primary">
				<form class="form-filter-by">
					<!-- loop over filters for horizontal menu -->
					<div class="filter-by-container" 
						v-for="(filter, index) in orderedOptions"
						:key="index"
						v-show="showOnHorizontal(filter.flags)"
						>
						<!-- filter.type == 'text' -->
						<div v-if="filter.type == 'text'" class="filter-by" >
							<input type="text"
								v-model="selected[filter.id]"
								:name="filter.id" 
								:id="filter.id"
								:placeholder="typeof filter.placeholder != 'undefined'?filter.placeholder:''"
								@input="filtersChanged=true"
							/>
						</div>
					</div>

					<invisible-recaptcha
						:sitekey="siteKey"
						:validate="validate" :callback="updateFilter"
						class="is-button is-button__basic--primary is-button__filter-by--search"
						type="submit" id="do-search-btn" :disabled="loading || !filtersChanged">
						Search
					</invisible-recaptcha>

					<p class="error-message">{{error}}</p>
				</form>

				<p class="total-results-of-providers">
					<span v-if="pages.total > 1">Showing {{results.start+1}}&ndash;{{results.end}} of </span>{{results.total}} provider<span v-if="results.total != 1">s</span> found
				</p>
			</div>

			<div class="filter-options filter-options--secondary">
				<div class="filter__order-by filter__order-by--ev-page-size">
					<!-- page size selector -->
					<div class="ev-page-size">
						<span>View:</span>

						<ul class="ev-page-size__options">
							<li class="ev-page-size__option"
								:class="{'is-active': option == page_sizes.selected }"
								:id="'pagesize-'+option+'-btn'"
								v-for="option in page_sizes.options"
								:key="option"
								>
								<a @click="updatePageSize(option)"
								>{{option}}</a>
							</li>
						</ul>
					</div>
				</div>

				<!-- filter show/hide button -->
				<button @click="toggleVerticalMenu" class="is-button is-button__basic--primary is-button--filter has-filter-by__list-count">
					<span class="filter-by__text">Filter</span>
					<span v-if="filterCount != 0" class="filter-by__list-count">{{filterCount}}</span>
				</button>
			</div>
		</div>

		<!-- vertical filters -->
		<div class="filter-container--vertical"
			v-if="active"
			>
			<form class="form-filter-by">
				<h3 class="filter-title">
					Filter by
					<a class="remove__indicator" @click="toggleVerticalMenu"></a>
				</h3>
				<!-- loop over options for vertical menu-->
				<div class="filter-by-container"
					v-for="(filter, index) in orderedOptions"
					:key="index"
					v-show="showOnVertical(filter.flags)"
					>
					<label :for="'vertical-filter-'+filter.id" class="is-label is-label--filter-by-type">{{filter.label}}</label>

					<!-- filter.type == 'text' -->
					<div class="filter-by--input"
						v-if="filter.type == 'text'" >
						<input type="text"
							v-model="selected[filter.id]"
							:name="filter.id" 
							:id="'vertical-filter-'+filter.id"
							:placeholder="typeof filter.placeholder != 'undefined'?filter.placeholder:''"
							@input="filtersChanged=true"
						/>
					</div>

					<!-- filter.type == 'checkbox-multi' -->
					<div class="filter-by--checkboxes"
						v-if="filter.type == 'checkbox-multi'"
					>
						<label class="selector__label"
							v-for="(option, index) in filter.options"
							:key="index" 
							>
							<input type="checkbox"
								v-model="selected[filter.id]"
								:value="option.value"
								:id="'vertical-filter-'+filter.id+'__'+option.label"
								@change="filtersChanged=true"
							>
							{{option.label}}

							<div class="selector__indicator"></div>
						</label>
					</div>

					<!-- filter.type == 'checkbox-single' -->

					<!-- filter.type == 'select-multi' -->

					<!-- filter.type == 'select-single' -->
					<div class="filter-by--select"
						v-if="filter.type == 'select-single'"
						>
						<select 
							:name="filter.id" 
							:id="'vertical-filter-'+filter.id"
							v-model="selected[filter.id]"
							@change="filtersChanged=true"
							>
							<option></option>
							<option 
								v-for="(option, index) in filter.options" 
								:key="index" 
								:value="option.value"
							>{{option.label}}</option>
						</select>
					</div>

					<!-- filter.type == 'tree-component' -->
					<div class="filter-by--liquorTree"
						v-if="filter.type == 'tree-component'"
					>	
						<LiquorTree :ref="'tree2__'+filter.id"
							:data="filter.options"
							:options="treeOptions"
							v-on:node:checked="updateSelectedTreeOptions"
							v-on:node:unchecked="updateSelectedTreeOptions"
							class="LiquorTree LiquorTree--deliver-service-to"
							aria-busy="true"
						/>
					</div>
				</div>
				<invisible-recaptcha
					:sitekey="siteKey"
					:validate="validate" :callback="updateFilter"
					class="is-button is-button__basic--primary is-button__filter-by--search"
					type="submit" id="vertical-search__do-search-btn" :disabled="loading || !filtersChanged">
					Search
				</invisible-recaptcha>

				<p v-if="error" class="error-message">{{error}}</p>

				<div class="filter-by-container filter-by-container--clear-all-filters">
					<a @click="clearFilters"
						class="is-link is-intext-link"
						>
						Clear all filters
					</a>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import InvisibleRecaptcha from 'vue-invisible-recaptcha';
	import LiquorTree from 'liquor-tree';
	import attribute_list from '@/services/attributeService.js';
	import config from '@/mixins/config';

	export default {
		props     : [
			'value','options','pages','results'
		],
		mixins: [config],
		components: {
			"invisible-recaptcha": InvisibleRecaptcha,
			LiquorTree,
			// page size component
		},
		data      : function() {
			return {
				active         : false,
				selected       : this.value,
				treeOptions    : {
					checkbox     : true,
					checkOnSelect:true
				},
				siteKey        : '',
				loading        : false,
				error          : '',
				appliedFilters : 0,
				page_sizes     : {
					selected   : typeof this.pages.size != 'undefined'?this.pages.size:10,
					options    : typeof this.pages.options != 'undefined'?this.pages.options:[10,20,50],
				},
				filtersChanged : false,
			}
		},
		computed: {
			orderedOptions: function () {
				var opts = this.options;
				return opts.sort(function(a, b){return a.sort_order - b.sort_order});
			},
			filterCount: function() {
				var self = this;
				var activeFilters = Object.keys(this.selected).filter(function(key) {
					return (Array.isArray(self.selected[key]) && self.selected[key].length > 0) || 
					(typeof self.selected[key] === 'string' && self.selected[key] != '')
				});
				return activeFilters.length;
			}
		},
		methods   : {
			populateSelectedTreeOptions(){
				this.$nextTick(() => {
					for (var i = 0; i < Object.keys(this.$refs).length; i++) {
						var thisKey = Object.keys(this.$refs)[i];
						var isTreeFilter = thisKey.split('__')[0] == 'tree2'?true:false;
						if (isTreeFilter) {
							this.$refs[thisKey][0].$on("tree:mounted", (tree) => {
								var filterFromKey = thisKey.split('__')[1];
								// eslint-disable-next-line no-console
								console.log(tree);
								var all = this.$refs[thisKey][0].findAll();
								all.uncheck();

								//idx in options array
								var selected = this.selected[filterFromKey];
								for (var j = 0; j < selected.length; j++) {
									var sel = this.$refs[thisKey][0].findAll({id:selected[j]})
									sel.check();
								}
							});
						}
					}
				})
			},
			clearSelectedTreeOptions(){
				this.$nextTick(() => {
					for (var i = 0; i < Object.keys(this.$refs).length; i++) {
						var thisKey = Object.keys(this.$refs)[i];
						var isTreeFilter = thisKey.split('__')[0] == 'tree2'?true:false;
						if (isTreeFilter) {
							var all = this.$refs[thisKey][0].findAll({ state: { checked:true }});
							all.uncheck();
						}
					}
				})
			},
			toggleVerticalMenu: function() {
				this.active = !this.active;

				if(this.active) {
					this.populateSelectedTreeOptions();
				}
			},
			showOnVertical(flags){
				return flags[0] == '1';
			},
			showOnHorizontal(flags){
				return flags[1] == '1';
			},
			isRequired(flags){
				return flags[2] == '1';
			},
			hasOptions(flags){
				return flags[3] == '1';
			},
			updateSelectedTreeOptions() {
				for (var i = 0; i < Object.keys(this.$refs).length; i++) {
					var thisKey = Object.keys(this.$refs)[i];
					var isTreeFilter = thisKey.split('__')[0] == 'tree2'?true:false;

					if (isTreeFilter) {
						var filterFromKey = thisKey.split('__')[1];
						this.selected[filterFromKey] = [];
						var selected = this.$refs[thisKey][0].findAll({ state: { checked:true }})
						var selected_ids = selected.map(function(e){ return e.id });
						this.selected[filterFromKey] = selected_ids;
					}
				}

				this.filtersChanged=true;
			},
			validate: function() {		
				if ( this.filterCount > 0 ) {
					this.error = '';
					return true;					
				} 
				this.error = "At least one option is required.";
				
				return false;
			},
			clearFilters: function() {		
				var self = this;

				Object.keys(this.selected).map(function(key) {
					if(Array.isArray(self.selected[key])) {
						self.selected[key] = [];
					}
					if(typeof self.selected[key] === 'string') {
						self.selected[key] = '';
					}
				});

				this.filtersChanged=true;

				this.clearSelectedTreeOptions();
			},
			updateFilter: function(tok) {				
				if(typeof this.initLoaded == 'undefined' || this.initLoaded) {
					this.$emit('filters-updated', {'selected':this.selected/*,'output':this.applied*/, 'tok':tok});
				}
				this.active        =false;
				this.filtersChanged=false;

			},
			updatePageSize: function(size) {
				this.page_sizes.selected = size;
				this.$emit('pages-updated', {'pagesize':this.page_sizes.selected});
			},
		},
		created: function() {
			this.siteKey = this.configSitekey();			
		},
		mounted: async function() {
			var attrs = await attribute_list.getAll();
			for (var i = 0; i < this.orderedOptions.length; i++) {
				switch(this.orderedOptions[i].id){
					case 'attributes':
						this.orderedOptions[i].options = attrs.rows.map( function(el) {
							return {
								label: el.name, 
								value: el.attributes_id
							}
						});
					break;
					case 'employees':
						this.orderedOptions[i].options = this.configEmployeeOptions();
					break;
					case 'regions_list':
						this.orderedOptions[i].options = this.configRegionsTreeData();
					break;
					case 'tier':
						this.orderedOptions[i].options = this.configSelectTierOptions();
					break;
				}
			}
		},
		watch     : {

		}
	};
</script>